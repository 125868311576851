var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Lời chúc mừng sinh nhật",
        visible: _vm.dialogVisible,
        width: "70%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "2vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "hr" }, [_c("hr")]),
      _c(
        "div",
        { staticClass: "table-content row-data" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                data: _vm.historyappsend,
                "highlight-current-row": "",
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeight,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "createdBy", label: "Người gửi", align: "left" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sendContent",
                  label: "Nội dung",
                  align: "center ",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "attachFile",
                  label: "Thiệp đính kèm ",
                  align: "center ",
                },
              }),
              _c(
                "el-table-column",
                {
                  attrs: { label: "Tác vụ", width: "300", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleconfirm(scope.row)
                                },
                              },
                            },
                            [_vm._v("Duyệt")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleRemove(scope.row)
                                },
                              },
                            },
                            [_vm._v("Thu hồi")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleRemove1(scope.row)
                                },
                              },
                            },
                            [_vm._v("Hủy thu hồi")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDeleteAppsend(
                                    scope.$index,
                                    scope.row
                                  )
                                },
                              },
                            },
                            [_vm._v("Xóa")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  !_vm.historyappsend.sendDel
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "warning" },
                          on: {
                            click: function ($event) {
                              return _vm.handleRemove()
                            },
                          },
                        },
                        [_vm._v("Thu hồi")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "warning" },
                          on: {
                            click: function ($event) {
                              return _vm.handleRemove1()
                            },
                          },
                        },
                        [_vm._v("Đã thu hồi")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }